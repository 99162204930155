import '../App.css';
import { useEffect, useState } from 'react';
import LineChart from '../components/LineChart';
import DoughnutChart from '../components/DoughnutChart';
import { useGlobalState } from '../globalState';
import { useNavigate, Link } from 'react-router-dom';
import * as api from "../api";
import Loader from '../components/loader';
import PullToRefresh from 'react-simple-pull-to-refresh';
import * as configuration from '../config';
import PrevArrow from '../images/prev.svg';
import NextArrow from '../images/next.svg';

function dataFormat(str) {
  return parseFloat(str.replace(/,/g, ''));
}

function numberWithCommas(x) {
  if (x != undefined) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

function DashboardPage() {

  const [tableData, setTableData] = useGlobalState('tableData');
  const [tableData2, setTableData2] = useGlobalState('tableData2');
  const [dataState, setDataState] = useState([]);

  const [yearLineChartData, setYearLineChartData] = useState({});
  const [yearLineChartData_REV, setYearLineChartData_REV] = useState({});

  const [sumYearLineChartData, setSumYearLineChartData] = useState({});
  const [sumYearLineChartData_REV, setSumYearLineChartData_REV] = useState({});

  const [dnDataMC, setDnDataMC] = useState({});
  const [dnOptionsMC, setDnOptionsMC] = useState({});
  const [dnDataPT, setDnDataPT] = useState({});
  const [dnOptionsPT, setDnOptionsPT] = useState({});
  const [dnDataTotal, setDnDataTotal] = useState({});
  const [dnOptionsTotal, setDnOptionsTotal] = useState({});

  const [dnDataMC_REV, setDnDataMC_REV] = useState({});
  const [dnOptionsMC_REV, setDnOptionsMC_REV] = useState({});
  const [dnDataPT_REV, setDnDataPT_REV] = useState({});
  const [dnOptionsPT_REV, setDnOptionsPT_REV] = useState({});
  const [dnDataTotal_REV, setDnDataTotal_REV] = useState({});
  const [dnOptionsTotal_REV, setDnOptionsTotal_REV] = useState({});

  const [currentMonth, setCurrentMonth] = useState({});

  const [isCurrentMonth, setIsCurrentMonth] = useState(true);

  let navigate = useNavigate();

  const colours = {
    "confirmed": "rgba(15,250,0,1)",
    "confirmedTint": "rgba(15,250,0,0.2)",
    "confirmedTintDark": "#04CF35",

    "target": "rgba(215,38,255,1)",
    "targetTint": "rgba(215,38,155,0.4)",

    "mc": "rgba(229,119,24,1)",
    "mcTint": "rgba(229,119,24,0.4)",
    "mcTintDark": "rgba(229,119,24,0.8)",
    "pt": "rgba(44,176,223,1)",
    "ptTint": "rgba(44,176,223,0.4)",
    "ptTintDark": "rgba(44,176,223,0.8)"
  }

  useEffect(() => {
    if (!sessionStorage.getItem("access_token")) {
      navigate("/");
    } else {

      const d = new Date();
      let prevMonth = yearData[d.getMonth() - 1].month;
      setCurrentMonth({
        "name": yearData[d.getMonth()].month,
        "number": d.getMonth()
      });


      api.getSheet(sessionStorage.getItem('access_token'), configuration.sheetName1).then((data) => {
        if (data.name == "AxiosError") {
          sessionStorage.clear();
          navigate("/");
        } else {
          console.log("AXIOS data:", data);
          setTableData(data);
        }
      });

      api.getSheet(sessionStorage.getItem('access_token'), configuration.sheetName2).then((data) => {
        if (data.name == "AxiosError") {
          sessionStorage.clear();
          navigate("/");
        } else {
          console.log("AXIOS data:", data);
          setTableData2(data);
        }
      });
    }
  }, []);

  const yearData = [
    { month: "January", row: 11 },
    { month: "February", row: 16 },
    { month: "March", row: 21 },
    { month: "April", row: 26 },
    { month: "May", row: 31 },
    { month: "June", row: 36 },
    { month: "July", row: 41 },
    { month: "August", row: 46 },
    { month: "September", row: 51 },
    { month: "October", row: 56 },
    { month: "November", row: 61 },
    { month: "December", row: 66 },
  ];


  let currentMonthData = dataState.filter((el) => {
    return el.month == currentMonth.name;
  });


  useEffect(() => {
    if (tableData.length && tableData2.length) {

      var yearDataState = [];

      yearData.map((data) => {

        //GP
        let targetMC = dataFormat(tableData[data.row][6]);
        let confirmedMC = dataFormat(tableData[data.row][7]);
        let varianceMC = targetMC - confirmedMC;
        let confirmedPercentageMC = Math.round(confirmedMC * 100 / targetMC);

        let targetPT = dataFormat(tableData[data.row][12]);
        let confirmedPT = dataFormat(tableData[data.row][13]);
        let variancePT = targetPT - confirmedPT;
        let confirmedPercentagePT = Math.round(confirmedPT * 100 / targetPT);


        let targetTotal = dataFormat(tableData[data.row][14]);
        let confirmedTotal = dataFormat(tableData[data.row][15]);
        let varianceTotal = targetTotal - confirmedTotal;
        let confirmedPercentageTotal = Math.round(confirmedTotal * 100 / targetTotal);
        //-

        //REV
        let targetMC_REV = dataFormat(tableData2[data.row][6]);
        let confirmedMC_REV = dataFormat(tableData2[data.row][7]);
        let varianceMC_REV = targetMC_REV - confirmedMC_REV;
        let confirmedPercentageMC_REV = Math.round(confirmedMC_REV * 100 / targetMC_REV);

        let targetPT_REV = dataFormat(tableData2[data.row][12]);
        let confirmedPT_REV = dataFormat(tableData2[data.row][13]);
        let variancePT_REV = targetPT_REV - confirmedPT_REV;
        let confirmedPercentagePT_REV = Math.round(confirmedPT_REV * 100 / targetPT_REV);


        let targetTotal_REV = dataFormat(tableData2[data.row][14]);
        let confirmedTotal_REV = dataFormat(tableData2[data.row][15]);
        let varianceTotal_REV = targetTotal_REV - confirmedTotal_REV;
        let confirmedPercentageTotal_REV = Math.round(confirmedTotal_REV * 100 / targetTotal_REV);
        //-

        yearDataState.push({
          month: data.month,

          //GP
          targetMC: targetMC,
          confirmedMC: confirmedMC,
          varianceMC: varianceMC,
          confirmedPercentageMC: confirmedPercentageMC,

          targetPT: targetPT,
          confirmedPT: confirmedPT,
          variancePT: variancePT,
          confirmedPercentagePT: confirmedPercentagePT,

          targetTotal: targetTotal,
          confirmedTotal: confirmedTotal,
          varianceTotal: varianceTotal,
          confirmedPercentageTotal: confirmedPercentageTotal,
          //-

          //REV
          targetMC_REV: targetMC_REV,
          confirmedMC_REV: confirmedMC_REV,
          varianceMC_REV: varianceMC_REV,
          confirmedPercentageMC_REV: confirmedPercentageMC_REV,

          targetPT_REV: targetPT_REV,
          confirmedPT_REV: confirmedPT_REV,
          variancePT_REV: variancePT_REV,
          confirmedPercentagePT_REV: confirmedPercentagePT_REV,

          targetTotal_REV: targetTotal_REV,
          confirmedTotal_REV: confirmedTotal_REV,
          varianceTotal_REV: varianceTotal_REV,
          confirmedPercentageTotal_REV: confirmedPercentageTotal_REV,
          //-


        })
      });
      setDataState(yearDataState);
    }
  }, [tableData, tableData2, currentMonth])

  useEffect(() => {

    console.log("CurrentMonthData: ", currentMonthData[0]);

    const sumConfirmedTotal = dataState.slice(0, currentMonth.number + 1).map((item) => {
      return item.confirmedTotal;
    });
    const sumTargetTotal = dataState.slice(0, currentMonth.number + 1).map((item) => {
      return item.targetTotal;
    });
    const confirmedTotalStart = dataState[0]?.confirmedTotal;
    const confirmedTotalEnd = sumConfirmedTotal.reduce((partialSum, a) => partialSum + a, 0);
    const targetTotalStart = dataState[0]?.targetTotal;
    const targetTotalEnd = sumTargetTotal.reduce((partialSum, a) => partialSum + a, 0);

    const sumConfirmedTotal_REV = dataState.slice(0, currentMonth.number + 1).map((item) => {
      return item.confirmedTotal_REV;
    });
    const sumTargetTotal_REV = dataState.slice(0, currentMonth.number + 1).map((item) => {
      return item.targetTotal_REV;
    });
    const confirmedTotalStart_REV = dataState[0]?.confirmedTotal_REV;
    const confirmedTotalEnd_REV = sumConfirmedTotal_REV.reduce((partialSum, a) => partialSum + a, 0);
    const targetTotalStart_REV = dataState[0]?.targetTotal_REV;
    const targetTotalEnd_REV = sumTargetTotal_REV.reduce((partialSum, a) => partialSum + a, 0);

    //Total GP LineChart
    setYearLineChartData({
      labels: dataState.slice(0, currentMonth.number + 1).map((item) => {
        var shortMonthName = item.month.substring(0, 3);
        return shortMonthName;
      }),
      datasets: [
        {
          label: "Target",
          data: dataState.slice(0, currentMonth.number + 1).map((item) => item.targetTotal),
          fill: true,
          borderColor: colours.target,
          backgroundColor: colours.targetTint
        },
        {
          label: "Confirmed",
          data: dataState.slice(0, currentMonth.number + 1).map((item) => item.confirmedTotal),
          fill: true,
          borderColor: colours.confirmed,
          backgroundColor: colours.confirmedTint
        }
      ]
    });
    //-

    //Total Revenue LineChart
    setYearLineChartData_REV({
      labels: dataState.slice(0, currentMonth.number + 1).map((item) => {
        var shortMonthName = item.month.substring(0, 3);
        return shortMonthName;
      }),
      datasets: [
        {
          label: "Target",
          data: dataState.slice(0, currentMonth.number + 1).map((item) => item.targetTotal_REV),
          fill: true,
          borderColor: colours.target,
          backgroundColor: colours.targetTint
        },
        {
          label: "Confirmed",
          data: dataState.slice(0, currentMonth.number + 1).map((item) => item.confirmedTotal_REV),
          fill: true,
          borderColor: colours.confirmed,
          backgroundColor: colours.confirmedTint
        }
      ]
    });
    //-

    //Sum Total GP LineChart
    setSumYearLineChartData({
      labels: [dataState[0]?.month.substring(0, 3), dataState[currentMonth.number]?.month.substring(0, 3)],
      datasets: [
        {
          label: "Target",
          data: [targetTotalStart, targetTotalEnd],
          fill: true,
          borderColor: colours.target,
          backgroundColor: colours.targetTint
        },
        {
          label: "Confirmed",
          data: [confirmedTotalStart, confirmedTotalEnd],
          fill: true,
          borderColor: colours.confirmed,
          backgroundColor: colours.confirmedTint
        }
      ]
    });
    //-

    //Sum Total Revenue LineChart
    setSumYearLineChartData_REV({
      labels: [dataState[0]?.month.substring(0, 3), dataState[currentMonth.number]?.month.substring(0, 3)],
      datasets: [
        {
          label: "Target",
          data: [targetTotalStart_REV, targetTotalEnd_REV],
          fill: true,
          borderColor: colours.target,
          backgroundColor: colours.targetTint
        },
        {
          label: "Confirmed",
          data: [confirmedTotalStart_REV, confirmedTotalEnd_REV],
          fill: true,
          borderColor: colours.confirmed,
          backgroundColor: colours.confirmedTint
        }
      ]
    });
    //-

    //MC GP Doughnut
    var DnLabelsMC = ["Variance", "Confirmed"];
    var DnDataMC = [currentMonthData[0]?.varianceMC, currentMonthData[0]?.confirmedMC];
    var DnBorderColorsMC = [colours.mc];
    var DnFillColorsMC = [colours.mc, '#313640'];
    if (currentMonthData[0]?.varianceMC < 0) {
      DnFillColorsMC = [colours.mcTintDark, colours.mc];
    }

    setDnDataMC({
      labels: currentMonthData[0]?.varianceMC < 0 ? DnLabelsMC : DnLabelsMC.reverse(),
      datasets: [{
        label: "target",
        data: currentMonthData[0]?.varianceMC < 0 ? DnDataMC : DnDataMC.reverse(),
        fill: true,
        borderColor: DnBorderColorsMC,
        backgroundColor: DnFillColorsMC,
        weight: 20
      }]
    });

    setDnOptionsMC({
      cutout: "80%",
      plugins: {
        legend: {
          display: false
        },
        doughnutlabel: {
          color: '#fff',
          labels: [{
            text: currentMonthData[0]?.confirmedPercentageMC + '%',
            font: {
              size: toString(currentMonthData[0]?.confirmedPercentageMC).length * 2,
              weight: 'bold'
            }
          }, {
            text: 'of target',
            font: {
              size: 15
            }
          }]
        }
      }
    });
    //-

    //PT GP Doughnut
    var DnLabelsPT = ["Variance", "Confirmed"];
    var DnDataPT = [currentMonthData[0]?.variancePT, currentMonthData[0]?.confirmedPT];
    var DnBorderColorsPT = ['rgba(44,176,223,1)'];
    var DnFillColorsPT = ['rgba(44,176,223,1)', '#313640'];
    if (currentMonthData[0]?.variancePT < 0) {
      DnFillColorsPT = ['rgba(44,176,223,0.8)', 'rgba(44,176,223,1)'];
    }

    setDnDataPT({
      labels: currentMonthData[0]?.variancePT < 0 ? DnLabelsPT : DnLabelsPT.reverse(),
      datasets: [{
        label: "target",
        data: currentMonthData[0]?.variancePT < 0 ? DnDataPT : DnDataPT.reverse(),
        fill: true,
        borderColor: DnBorderColorsPT,
        backgroundColor: DnFillColorsPT,
        weight: 20
      }]
    });

    setDnOptionsPT({
      cutout: "80%",
      plugins: {
        legend: {
          display: false
        },
        doughnutlabel: {
          color: '#fff',
          labels: [{
            text: currentMonthData[0]?.confirmedPercentagePT + '%',
            font: {
              size: toString(currentMonthData[0]?.confirmedPercentagePT).length * 2,
              weight: 'bold'
            }
          }, {
            text: 'of target',
            font: {
              size: 15
            }
          }]
        }
      }
    });
    //-

    //Totals GP Doughnut
    var DnLabels = ["Variance", "Confirmed"];
    var DnData = [currentMonthData[0]?.varianceTotal, currentMonthData[0]?.confirmedTotal];
    var DnBorderColors = ['#0FFA00'];
    var DnFillColors = ['#0FFA00', '#313640'];
    if (currentMonthData[0]?.varianceTotal < 0) {
      DnFillColors = ['#04CF35', '#0FFA00'];
    }

    setDnDataTotal({
      labels: currentMonthData[0]?.varianceTotal < 0 ? DnLabels : DnLabels.reverse(),
      datasets: [{
        label: "target",
        data: currentMonthData[0]?.varianceTotal < 0 ? DnData : DnData.reverse(),
        fill: true,
        borderColor: DnBorderColors,
        backgroundColor: DnFillColors,
        weight: 20
      }]
    });

    setDnOptionsTotal({
      cutout: "80%",
      plugins: {
        legend: {
          display: false
        },
        doughnutlabel: {
          color: '#fff',
          labels: [{
            text: currentMonthData[0]?.confirmedPercentageTotal + '%',
            font: {
              size: toString(currentMonthData[0]?.confirmedPercentagePT).length * 2,
              weight: 'bold'
            }
          }, {
            text: 'of target',
            font: {
              size: 15
            }
          }]
        }
      }
    });
    //-

    //MC REV Doughnut
    var DnLabelsMC_REV = ["Variance", "Confirmed"];
    var DnDataMC_REV = [currentMonthData[0]?.varianceMC_REV, currentMonthData[0]?.confirmedMC_REV];
    var DnBorderColorsMC_REV = ['rgba(229,119,24,1)'];
    var DnFillColorsMC_REV = ['rgba(229,119,24,1)', '#313640'];
    if (currentMonthData[0]?.varianceMC_REV < 0) {
      DnFillColorsMC_REV = ['rgba(229,119,24,0.8)', 'rgba(229,119,24,1)'];
    }

    setDnDataMC_REV({
      labels: currentMonthData[0]?.varianceMC_REV < 0 ? DnLabelsMC_REV : DnLabelsMC_REV.reverse(),
      datasets: [{
        label: "target",
        data: currentMonthData[0]?.varianceMC_REV < 0 ? DnDataMC_REV : DnDataMC_REV.reverse(),
        fill: true,
        borderColor: DnBorderColorsMC_REV,
        backgroundColor: DnFillColorsMC_REV,
        weight: 20
      }]
    });

    setDnOptionsMC_REV({
      cutout: "80%",
      plugins: {
        legend: {
          display: false
        },
        doughnutlabel: {
          color: '#fff',
          labels: [{
            text: currentMonthData[0]?.confirmedPercentageMC_REV + '%',
            font: {
              size: toString(currentMonthData[0]?.confirmedPercentageMC_REV).length * 2,
              weight: 'bold'
            }
          }, {
            text: 'of target',
            font: {
              size: 15
            }
          }]
        }
      }
    });
    //-

    //PT REV Doughnut
    var DnLabelsPT_REV = ["Variance", "Confirmed"];
    var DnDataPT_REV = [currentMonthData[0]?.variancePT_REV, currentMonthData[0]?.confirmedPT_REV];
    var DnBorderColorsPT_REV = ['rgba(44,176,223,1)'];
    var DnFillColorsPT_REV = ['rgba(44,176,223,1)', '#313640'];
    if (currentMonthData[0]?.variancePT_REV < 0) {
      DnFillColorsPT_REV = ['rgba(44,176,223,0.8)', 'rgba(44,176,223,1)'];
    }

    setDnDataPT_REV({
      labels: currentMonthData[0]?.variancePT_REV < 0 ? DnLabelsPT_REV : DnLabelsPT_REV.reverse(),
      datasets: [{
        label: "target",
        data: currentMonthData[0]?.variancePT_REV < 0 ? DnDataPT_REV : DnDataPT_REV.reverse(),
        fill: true,
        borderColor: DnBorderColorsPT_REV,
        backgroundColor: DnFillColorsPT_REV,
        weight: 20
      }]
    });

    setDnOptionsPT_REV({
      cutout: "80%",
      plugins: {
        legend: {
          display: false
        },
        doughnutlabel: {
          color: '#fff',
          labels: [{
            text: currentMonthData[0]?.confirmedPercentagePT_REV + '%',
            font: {
              size: toString(currentMonthData[0]?.confirmedPercentagePT_REV).length * 2,
              weight: 'bold'
            }
          }, {
            text: 'of target',
            font: {
              size: 15
            }
          }]
        }
      }
    });
    //-

    //Totals REV Doughnut
    var DnLabels_REV = ["Variance", "Confirmed"];
    var DnData_REV = [currentMonthData[0]?.varianceTotal_REV, currentMonthData[0]?.confirmedTotal_REV];
    var DnBorderColors_REV = ['#0FFA00'];
    var DnFillColors_REV = ['#0FFA00', '#313640'];
    if (currentMonthData[0]?.varianceTotal_REV < 0) {
      DnFillColors_REV = ['#04CF35', '#0FFA00'];
    }

    setDnDataTotal_REV({
      labels: currentMonthData[0]?.varianceTotal_REV < 0 ? DnLabels_REV : DnLabels_REV.reverse(),
      datasets: [{
        label: "target",
        data: currentMonthData[0]?.varianceTotal_REV < 0 ? DnData_REV : DnData_REV.reverse(),
        fill: true,
        borderColor: DnBorderColors_REV,
        backgroundColor: DnFillColors_REV,
        weight: 20
      }]
    });

    setDnOptionsTotal_REV({
      cutout: "80%",
      plugins: {
        legend: {
          display: false
        },
        doughnutlabel: {
          color: '#fff',
          labels: [{
            text: currentMonthData[0]?.confirmedPercentageTotal_REV + '%',
            font: {
              size: toString(currentMonthData[0]?.confirmedPercentagePT_REV).length * 2,
              weight: 'bold'
            }
          }, {
            text: 'of target',
            font: {
              size: 15
            }
          }]
        }
      }
    });
    //-


  }, [dataState]);

  const lineOptions = {
    radius: 5,
    color: "white",
    legend: {
      labels: {
        padding: 40
      }
    },
    scales: {
      x: {
        ticks: {
          color: "white"
        },
        grid: {
          color: "#313640"
        }
      },
      y: {
        ticks: {
          color: "white"
        },
        grid: {
          color: "#313640"
        }
      }
    }
  };

  function handleRefresh() {
    window.location.reload();
    return true;
  }

  return (
    <>
      <PullToRefresh onRefresh={handleRefresh} className="pullRefresh">
        <div style={{ height: "100%" }}>
          {!tableData.length && !tableData2.length && <Loader />}
          <div className='Section'>
            {/* <h3 className='SectionTitle'>Sales By GP</h3> */}
            <div className='CardsContainer'>
              <div className='CardWrapper CardWrapperMain Doughnut'>
                <div className='Card'>
                  <div className='Card-header'><strong>Sales by GP Total:</strong><span>{currentMonthData[0]?.month}</span></div>
                  <div className='Card-content'>
                    <div className='Chart'>
                      {dataState.length && <DoughnutChart chartData={dnDataTotal} options={dnOptionsTotal} />}
                    </div>
                    <div className='Labels'>
                      <p><b>Target:</b> {numberWithCommas(currentMonthData[0]?.targetTotal)}</p>
                      <p><b>Confirmed:</b> {numberWithCommas(currentMonthData[0]?.confirmedTotal)}</p>
                      <p><b>Variance:</b> {numberWithCommas(currentMonthData[0]?.varianceTotal)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='CardWrapper Doughnut'>
                <div className='Card'>
                  <div className='Card-header' style={{ justifyContent: "center" }}><strong>Morocco</strong>
                    {/* <span>{currentMonthData[0]?.month}</span> */}
                  </div>
                  <div className='Card-content'>
                    <div className='Chart'>
                      {dataState.length && <DoughnutChart chartData={dnDataMC} options={dnOptionsMC} />}
                    </div>
                    {/* <div className='Labels'>
                    <p><b>Target:</b> {numberWithCommas(currentMonthData[0]?.targetMC)}</p>
                    <p><b>Confirmed:</b> {numberWithCommas(currentMonthData[0]?.confirmedMC)}</p>
                    <p><b>Variance:</b> {numberWithCommas(currentMonthData[0]?.varianceMC)}</p>
                  </div> */}
                  </div>
                </div>
              </div>
              <div className='CardWrapper Doughnut'>
                <div className='Card'>
                  <div className='Card-header' style={{ justifyContent: "center" }}><strong>Portugal</strong>
                    {/* <span>{currentMonthData[0]?.month}</span> */}
                  </div>
                  <div className='Card-content'>
                    <div className='Chart'>
                      {dataState.length && <DoughnutChart chartData={dnDataPT} options={dnOptionsPT} />}
                    </div>
                    {/* <div className='Labels'>
                    <p><b>Target:</b> {numberWithCommas(currentMonthData[0]?.targetPT)}</p>
                    <p><b>Confirmed:</b> {numberWithCommas(currentMonthData[0]?.confirmedPT)}</p>
                    <p><b>Variance:</b> {numberWithCommas(currentMonthData[0]?.variancePT)}</p>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='Section'>
            {/* <h3 className='SectionTitle'>Sales By Revenue</h3> */}
            <div className='CardsContainer'>
              <div className='CardWrapper CardWrapperMain Doughnut'>
                <div className='Card'>
                  <div className='Card-header'><strong>Sales by Revenue Total:</strong><span>{currentMonthData[0]?.month}</span></div>
                  <div className='Card-content'>
                    <div className='Chart'>
                      {dataState.length && <DoughnutChart chartData={dnDataTotal_REV} options={dnOptionsTotal_REV} />}
                    </div>
                    <div className='Labels'>
                      <p><b>Target:</b> {numberWithCommas(currentMonthData[0]?.targetTotal_REV)}</p>
                      <p><b>Confirmed:</b> {numberWithCommas(currentMonthData[0]?.confirmedTotal_REV)}</p>
                      <p><b>Variance:</b> {numberWithCommas(currentMonthData[0]?.varianceTotal_REV)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='CardWrapper Doughnut'>
                <div className='Card'>
                  <div className='Card-header' style={{ justifyContent: "center" }}><strong>Morocco</strong>
                    {/* <span>{currentMonthData[0]?.month}</span> */}
                  </div>
                  <div className='Card-content'>
                    <div className='Chart'>
                      {dataState.length && <DoughnutChart chartData={dnDataMC_REV} options={dnOptionsMC_REV} />}
                    </div>
                    {/* <div className='Labels'>
                    <p><b>Target:</b> {numberWithCommas(currentMonthData[0]?.targetMC_REV)}</p>
                    <p><b>Confirmed:</b> {numberWithCommas(currentMonthData[0]?.confirmedMC_REV)}</p>
                    <p><b>Variance:</b> {numberWithCommas(currentMonthData[0]?.varianceMC_REV)}</p>
                  </div> */}
                  </div>
                </div>
              </div>
              <div className='CardWrapper Doughnut'>
                <div className='Card'>
                  <div className='Card-header' style={{ justifyContent: "center" }}><strong>Portugal</strong>
                    {/* <span>{currentMonthData[0]?.month}</span> */}
                  </div>
                  <div className='Card-content'>
                    <div className='Chart'>
                      {dataState.length && <DoughnutChart chartData={dnDataPT_REV} options={dnOptionsPT_REV} />}
                    </div>
                    {/* <div className='Labels'>
                    <p><b>Target:</b> {numberWithCommas(currentMonthData[0]?.targetPT_REV)}</p>
                    <p><b>Confirmed:</b> {numberWithCommas(currentMonthData[0]?.confirmedPT_REV)}</p>
                    <p><b>Variance:</b> {numberWithCommas(currentMonthData[0]?.variancePT_REV)}</p>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='Section'>
            <div className='CardWrapper CardWrapperMain LineChart'>
              <div className='Card'>
                <div className='Card-header'><strong>Total Sales By GP</strong><span>2022</span></div>
                <div className='Card-content'>
                  {dataState.length && <LineChart chartData={yearLineChartData} options={lineOptions} />}
                </div>
              </div>
            </div>
            <div className='CardWrapper CardWrapperMain LineChart'>
              <div className='Card'>
                <div className='Card-header'><strong>Sum Total Sales By GP</strong><span>2022</span></div>
                <div className='Card-content'>
                  {dataState.length && <LineChart chartData={sumYearLineChartData} options={lineOptions} />}
                </div>
              </div>
            </div>
          </div>
          <div className='Section'>
            <div className='CardWrapper CardWrapperMain LineChart'>
              <div className='Card'>
                <div className='Card-header'><strong>Total Sales By Revenue</strong><span>2022</span></div>
                <div className='Card-content'>
                  {dataState.length && <LineChart chartData={yearLineChartData_REV} options={lineOptions} />}
                </div>
              </div>
            </div>
            <div className='CardWrapper CardWrapperMain LineChart'>
              <div className='Card'>
                <div className='Card-header'><strong>Sum Total Sales By Revenue</strong><span>2022</span></div>
                <div className='Card-content'>
                  {dataState.length && <LineChart chartData={sumYearLineChartData_REV} options={lineOptions} />}
                </div>
              </div>
            </div>
          </div>
          <div className='BottomNav'>
            <div className='ButtonGroup'>
              <button className={!isCurrentMonth ? 'active' : ''} onClick={() => {
                const d = new Date();
                if (currentMonth.number != 0) {
                  setCurrentMonth({
                    "name": yearData[d.getMonth() - 1].month,
                    "number": d.getMonth() - 1
                  });
                  setIsCurrentMonth(false);
                }
              }}><span>Previous</span></button>
              <button className={isCurrentMonth ? 'active' : ''} onClick={() => {
                const d = new Date();
                setCurrentMonth({
                  "name": yearData[d.getMonth()].month,
                  "number": d.getMonth()
                });
                setIsCurrentMonth(true);
              }}><span>Current</span></button>
            </div>
          </div>
        </div>
      </PullToRefresh>
    </>
  )
}

export default DashboardPage