import React from 'react'
import loader from '../images/loading-gif.gif'

function Loader() {
  return (
    <div style={{display: "flex", background: "#1b1e25", justifyContent: "center", alignItems: "center", width: "100%", position: "absolute", zIndex: "20", height: "100vh", left: "0", top: "0"}}>
        <div style={{width: "60px"}}>
            <img style={{width: "100%"}} src={loader} />
        </div>
    </div>
  )
}

export default Loader