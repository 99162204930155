export const developmentClientId = "741521752465-ud84s36re4rd8o8st3mon7okd70ngvqg.apps.googleusercontent.com";
//export const productionClientId = "964083639204-9pl0q6kd94n38s5rkcdjids3pijskilo.apps.googleusercontent.com";
export const productionClientId = "741521752465-ud84s36re4rd8o8st3mon7okd70ngvqg.apps.googleusercontent.com";

export const developmentApiUrl = "http://localhost/gsheets-api/";
//export const productionApiUrl = "https://dash.appserve.space/gsheets-api/";
export const productionApiUrl = "https://thrive.htcstaging.space/gsheets-api/";

export const developmentGoogleSheetId = "1wW8DOJTfODeEiuq6gwibOJAWyoK5dlx2GNlTTbGtLpY";
//export const productionGoogleSheetId = "16qk8LQ3XmyyGbcxwrLMi4WKSHyPC3Dcqc9pHh78wwHA";
export const productionGoogleSheetId = "1wW8DOJTfODeEiuq6gwibOJAWyoK5dlx2GNlTTbGtLpY";

export const sheetName1 = "SALES BY GP 2022";
export const sheetName2 = "SALES BY REVENUE 2022";