import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Login from '../components/login';
import EpicLogo from '../images/epic-dash-logo-white.svg'
import { useNavigate } from 'react-router-dom';
import * as configuration from "../config";

function LoginPage() {
  let navigate = useNavigate();

  if (sessionStorage.getItem("access_token")) {
    navigate("/dashboard");
  }


  var clientId = "";
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    clientId = configuration.developmentClientId;
  } else {
    clientId = configuration.productionClientId;
  }

  return (
    <div className='LoginWrapper'>
      <div>
        <div><img className='LoginLogo' src={EpicLogo} alt="Epic Dash" /></div>
        <GoogleOAuthProvider clientId={clientId}>
          <Login />
        </GoogleOAuthProvider>
      </div>
    </div>
  )
}

export default LoginPage