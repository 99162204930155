import { createGlobalState } from "react-hooks-global-state";


const initialState = {
    tableData: [],
    tableData2: []
};

const {setGlobalState, useGlobalState} = createGlobalState(initialState);

export {setGlobalState, useGlobalState};