import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import LogoutIcon from '../images/logout.svg'

import EpicLogo from '../images/epic-dash-logo-white.svg'

function NavBar() {
let navigate = useNavigate();

  return (
    <nav className='Navbar'>
      <ul>
        <li className='Navlink'>
          <img className='Logo' src={EpicLogo} />
        </li>
        <li className='Navlink'><Link to={"/logout"}><img src={LogoutIcon} /><span>Logout</span></Link></li>
      </ul>
    </nav>
  )
}

export default NavBar