import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import NavBar from './components/NavBar';

import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import LogoutPage from './pages/LogoutPage';

function App() {

  return (
    <div className='App'>
    <Router>
      <Routes>
        <Route path="*" element={<LoginPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard" element={<><DashboardPage /><NavBar /></>} />
        <Route path="/logout" element={<LogoutPage />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
